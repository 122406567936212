
import { connect } from "react-redux"
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from "react";
import { check_authenticated, load_user, login, refresh, reset_password_confirm } from "../../redux/actions/auth/auth";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import HomeLayout from "../../hocs/layout/HomeLayout";
import { Helmet } from "react-helmet-async";
import imgLogo from "../../assets/img/cf-logo.svg";
import { Meta } from "../../components/navigation/Helmet";

function ResetPasswordConfirm({
    reset_password_confirm,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
}){

    const params = useParams()
    const uid = params.uid
    const token = params.token

    useEffect(()=>{
        isAuthenticated ? <></>:
        <>
        {refresh()}
        {check_authenticated()}
        {load_user()}
        </>
    },[])

    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { 
        new_password,
        re_new_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const navigate = useNavigate()

    const onSubmit = e => {
        e.preventDefault();
        reset_password_confirm(uid, token, new_password, re_new_password)
        navigate('/')
    }

    if(isAuthenticated){
        return <Navigate to='/dashboard'/>
    }
  

    return(
        <HomeLayout>
          <Meta title="Restablecer contraseña" />
          <div className="mx-auto bg-white flex w-full max-w-md justify-center mt-32 shadow-lg p-4 rounded-lg">
            <div className="w-full max-w-md space-y-6">
              <div>
                <img
                  className="mx-auto h-24 w-auto"
                  src={imgLogo}
                  alt="Your Company"
                />
              </div>
              <div>
                <p className="font-bold text-center">Ingresa tu nueva contraseña</p>
              </div>
              <form onSubmit={e=>{onSubmit(e)}} className="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Nueva contraseña
                    </label>
                    <input
                      name="new_password"
                      value={new_password}
                      onChange={e=>onChange(e)}
                      type="password"
                      required
                      className="input input-bordered w-full appearance-none border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm mb-4"
                      placeholder="Nueva contraseña"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Repetir nueva contraseña
                    </label>
                    <input
                      name="re_new_password"
                      value={re_new_password}
                      onChange={e=>onChange(e)}
                      type="password"
                      required
                      className="input input-bordered w-full appearance-none border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                      placeholder="Repita nueva contraseña"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-green-500 group-hover:text-green-400" aria-hidden="true" />
                    </span>
                    Cambiar contraseña
                  </button>
                </div>
              </form>
            </div>
          </div>
        </HomeLayout>
    )
}

const mapStateToProps=state=>({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading
})

export default connect(mapStateToProps,{
    reset_password_confirm,
    refresh,
    check_authenticated,
    load_user,
}) (ResetPasswordConfirm)