import { Link } from "react-router-dom";
import logoimg from "../../assets/img/cf-logo.svg";

import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { Helmet } from "react-helmet-async";

function Error404() {
  return (
    <DashboardLayout>
			<Helmet>
				<title>404 | CF BOT</title>
			</Helmet>
      <div className="py-20 flex flex-col justify-center items-center">
        <Link to="/">
          <img src={logoimg} className="w-32" alt="logo" />
        </Link>
        <h1 className="text-[14rem] leading-none m-0 text-center font-black text-gray-500">
          404
        </h1>
        <span className="text-sm">
          La página a la que intentas acceder no está disponible
        </span>
        <span className="text-sm">Pulsa en el logo para volver al inicio</span>
      </div>
    </DashboardLayout>
  );
}
export default Error404;
