import { DATA_SUCCESS, DATA_FAIL } from "../actions/datos/types";


const initialState = {
	data: [],
	count : null,
	next : null,
	previous : null,
};


export default function data(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DATA_SUCCESS:
			return {
				...state,
				data: payload.results,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case DATA_FAIL:
			return {
				...state,
				data: [],
				count: null,
				next: null,
				previous: null,
			};
		default:
			return state;
	}
}