import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import profileImg from "../../assets/img/profile-img.png";
import { IconDiscountCheck, IconPencil } from "@tabler/icons-react";
import Loading from "../../components/Loading";
import bannerImg from "../../assets/img/banner.png";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Meta } from "../../components/navigation/Helmet";

function Profile() {
	const user = useSelector((state) => state.auth.user);
	const [profileData, setProfileData] = useState(null);

	useEffect(() => {}, []);

	return (
		<DashboardLayout>
			<Meta title="Perfil" />
			<div className="container mx-auto max-w-3xl relative">
				<div className="banner-profile">
					<img src={bannerImg} className="rounded-xl" alt="Banner profile" />
				</div>
				{user ? (
					<div className="flex flex-col items-center relative">
						<div className="flex flex-col md:flex-row md:justify-between lg:absolute -top-6 lg:-top-10 px-4 py-3 bg-white md:bg-transparent shadow-lg w-full rounded-b-lg md:shadow-none">
							<div className="profile-data flex flex-col md:flex-row items-center gap-4">
								<div className="relative drop-shadow-md">
									<div className="p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center bg-white">
										<div
											className={
												"p-1 rounded-full overflow-hidden w-16 h-16 md:w-28 md:h-28 flex justify-center items-center bg-yellow-500"
											}
										>
											<img
												src={profileImg}
												className="h-12 w-12 md:h-24 md:w-24"
												alt="Image profile"
											/>
										</div>
									</div>
									<div>
										{user && user.verified ? (
											<div className="bg-green-500 rounded-full p-0.5 absolute bottom-2.5 right-2.5">
												<IconDiscountCheck className="text-white" />
											</div>
										) : (
											<div className="bg-green-500 rounded-full p-0.5 absolute bottom-2.5 right-2.5 hidden">
												<IconDiscountCheck className="text-white" />
											</div>
										)}
									</div>
								</div>
								<div className="flex flex-col items-center md:items-start">
									<h2 className="font-bold text-lg uppercase text-[#00496B]">
										{user.first_name + " " + user.last_name}
									</h2>
									<h3 className="text-sm uppercase text-[#00496B]">
										{user.username}
									</h3>
									<h4 className="font-thin text-sm uppercase text-[#00496B]">
										{user.department} / {user.role}
									</h4>
								</div>
							</div>
							<div className="flex items-center justify-center md:justify-end">
								<div>
									<span className="font-bold text-sm text-[#00496B]">
										Registrado:{" "}
									</span>
									<span className="text-sm text-[#00496B]">

									</span>
								</div>
							</div>
						</div>
						<div className="mt-5 lg:mt-32 w-full">
							<div className="flex justify-center">
							<Link
						to={`/edit-user/${user.id}`}
						className="p-1 text-white bg-yellow-500 rounded-full"
					>
						<IconPencil />
					</Link>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-3"></div>
						</div>
					</div>
				) : (
					<div className="w-full h-full flex justify-center items-center">
						<Loading />
					</div>
				)}
			</div>
		</DashboardLayout>
	);
}
export default Profile;
