import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "../../components/Alert";

function PrivateRoute({ children }) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (!auth) {
    Toast({ tipo: "error", mensaje: "No tienes acceso a esta zona!" });
    return <Navigate to="/" />;
  }
  return children;
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PrivateRoute);
