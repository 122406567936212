import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Toast = ({ tipo, mensaje }) => {
  switch (tipo) {
    case "success":
      toast.success(mensaje);
      break;
    case "error":
      toast.error(mensaje);
      break;
    case "info":
      toast.info(mensaje);
      break;
    default:
      break;
  }

  return null;
};

export default Toast;
