import { combineReducers } from "redux";
import auth from "./auth.js";
import alert from "./alert";
import employees from "./user";
import data from "./data.js";
export default combineReducers({
  //  Autenticacion
  auth,
  //  Alerta
  alert,
	//  User
	employees,
	//  Data
	data
});
	