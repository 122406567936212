import {
	check_authenticated,
	load_user,
	logout,
	refresh,
} from "../../redux/actions/auth/auth";
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
	IconCheck,
	IconHome,
	IconTicket,
	IconShoppingBag,
	IconDoorExit,
} from "@tabler/icons-react";
import { NavLink, useLocation } from "react-router-dom";
import logoimg from "../../assets/img/cf-logo.svg";
import Toast from "../../components/Alert";
import "../../assets/css/home.css";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function DashboardNavbar({
	refresh,
	check_authenticated,
	load_user,
	user,
	logout,
}) {
	useEffect(() => {
		refresh();
		check_authenticated();
		load_user();
	}, [check_authenticated, load_user, refresh]);

	const [open, setOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const navigate = useNavigate();
	const handleLogout = () => {
		logout();

		const redirectToLogin = () => {
			Toast({
				tipo: "success",
				mensaje: "¡Te has desautenticado correctamente!",
			});
			navigate("/");
			window.location.reload();
		};
		setOpen(false);
		redirectToLogin();
	};

	const auth = JSON.parse(localStorage.getItem("auth"));

	const location = useLocation();
	const navigation = [
		{
			name: "Dashboard",
			href: "/dashboard",
			icon: IconHome,
			current: location.pathname === "/dashboard" ? true : false,
		},
		{
			name: "Información Archivos",
			href: "/data-file",
			icon: IconShoppingBag,
			current: location.pathname === "/data-file" ? true : false,
		},

		{
			name: "Preguntas y Respuestas",
			href: "/data-question",
			icon: IconTicket,
			current: location.pathname === "/data-question" ? true : false,
		},
	];

	const logoutItem = (
    <button
        key="logout"
        onClick={handleLogout}
        className={classNames(
            "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
            user ? "text-gray-500 hover:bg-white hover:text-[#f69100]" : "hidden"
        )}
    >
        <IconDoorExit className="mr-1.5 flex-shrink-0 h-6 w-6 text-gray-500" aria-hidden="true" />
        Salir
    </button>
);

const navLinks = navigation.map((item) => (
    <NavLink
        key={item.name}
        to={item.href}
        className={classNames(
            item.current
                ? "bg-[#7900AC] text-white"
                : "text-gray-500 hover:bg-white hover:text-[#7900AC]",
            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
    >
        {item.icon && (
            <item.icon
                className={classNames(
                    item.current ? "text-white" : "text-gray-500",
                    "mr-1.5 flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
            />
        )}
        {item.name}
    </NavLink>
));

	return (
		<>
			<header className="w-full bg-cover bg-[#1b1b1b]">
				<div className="container mx-auto">
					<nav className="flex items-center justify-between ">
						<div className="brand">
							<Link to={"/dashboard"}>
								<img src={logoimg} className="w-20 navbar-end" alt="Logo" />
							</Link>
						</div>
						<div className="dropdown dropdown-end">
							<button
								tabIndex={0}
								className="btn btn-ghost md:hidden"
								onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="#fff"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M4 6h16M4 12h8m-8 6h16"
									/>
								</svg>
							</button>
							<Transition
								show={isMobileMenuOpen}
								enter="transition ease-out duration-100 transform"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="transition ease-in duration-75 transform"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								{(ref) => (
									<div
										ref={ref}
										className="menu menu-sm dropdown-content mt-3 z-10 p-2 shadow bg-base-100 rounded-box w-52"
									>
										<div className="my-2 font-bold">
											{user && user.first_name} {user && user.last_name}
										</div>
										{navigation.map((item) => (
											<NavLink
												key={item.name}
												to={item.href}
												className={classNames(
													item.current
														? "bg-[#f69100] text-white"
														: "text-gray-500 hover:bg-white hover:text-[#f69100]",
													"group flex items-center px-2 py-2 text-sm font-medium rounded-md"
												)}
											>
												<item.icon
													className={classNames(
														item.current ? "text-white" : "text-gray-500",
														"mr-1.5 flex-shrink-0 h-6 w-6"
													)}
													aria-hidden="true"
												/>
												{item.name}
											</NavLink>
										))}
								<div>
                    {logoutItem}
                </div>
									</div>
								)}
							</Transition>
						</div>
						{auth ? (
							<div className="navbar justify-center hidden md:flex">
								<div className="gap-2">
									{navigation.map((item) => (
										<NavLink
											key={item.name}
											to={item.href}
											className={classNames(
												item.current
													? "bg-[#f69100] text-white"
													: "text-gray-500 hover:bg-white hover:text-[#f69100]",
												"group flex items-center px-2 py-2 text-sm font-medium rounded-md"
											)}
										>
											<item.icon
												className={classNames(
													item.current ? "text-white" : "text-gray-500",
													"mr-1.5 flex-shrink-0 h-6 w-6"
												)}
												aria-hidden="true"
											/>
											{item.name}
										</NavLink>
									))}
								<div>
                    {logoutItem}
                </div>
								</div>
							</div>
						) : (
							<div className="menu text-white"></div>
						)}
					</nav>
				</div>
			</header>
		</>
	);
}

const mapStateToProps = (state) => ({
	user_loading: state.auth.user_loading,
	user: state.auth.user,
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
	refresh,
	check_authenticated,
	load_user,
	logout,
})(DashboardNavbar);
