import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Error404 from "../../containers/errors/Error404";
import Login from "../../containers/Login";
import Dashboard from "../../containers/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import Profile from "../../containers/pages/Profile";
import ResetPassword from "../../containers/auth/ResetPassword";
import ResetPasswordConfirm from "../../containers/auth/ResetPasswordConfirm";
import ActivateAccount from "../../containers/auth/ActivateAccount";
import Data from "../../containers/pages/Data-File/Data-File";
import DataQuestion from "../../containers/pages/Data-Question/Data-Question";
import DataForm from "../../containers/pages/Data-File/FormDataFile";

function AnimatedRoutes() {
	const location = useLocation();
	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path="*" element={<Error404 />} />
				<Route path="/" element={<Login />} />

				<Route path="/forgot_password" element={<ResetPassword />} />
				<Route
					path="/password/reset/confirm/:uid/:token"
					element={<ResetPasswordConfirm />}
				/>
				<Route path="/active/:uid/:token" element={<ActivateAccount />} />

				{/* Inicio dash */}
				<Route
					path="/dashboard"
					element={
						<PrivateRoute>
							<Dashboard />
						</PrivateRoute>
					}
				/>

				<Route
					path="/profile"
					element={
						<PrivateRoute>
							<Profile />
						</PrivateRoute>
					}
				/>

				<Route
					path="/data-file"
					element={
						<PrivateRoute>
							<Data />
						</PrivateRoute>
					}
				/>

				<Route
					path="/data-question"
					element={
						<PrivateRoute>
							<DataQuestion />
						</PrivateRoute>
					}
				/>

				<Route path="/form-data-file"
					element={
						<PrivateRoute>
							<DataForm />
						</PrivateRoute>
					}
				/>
				
			</Routes>
		</AnimatePresence>
	);
}

export default AnimatedRoutes;
