import React, { useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Meta } from "../../../components/navigation/Helmet";
import axios from "axios";
import DeveloperImg from "../../../assets/images/developer-img.jpg";

function DataQuestion() {
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(5);

    const addQuestion = () => {
        setQuestions([...questions, { pregunta: "", respuestas: "", saved: false }]);
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = questions.map((q, i) =>
            i === index ? { ...q, pregunta: value } : q
        );
        setQuestions(updatedQuestions);
    };

    const handleAnswerChange = (index, value) => {
        const updatedQuestions = questions.map((q, i) =>
            i === index ? { ...q, respuestas: value } : q
        );
        setQuestions(updatedQuestions);
    };

    const saveQuestion = async (index) => {
        const question = questions[index];
        const config = {
            headers: {
                Authorization: `JWT ${localStorage.getItem("access")}`,
                Accept: "application/json",
            },
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/`, {
                question: question.pregunta,
                answer: question.respuestas,
                action: "process_question",
            }, config);
            if (response.status === 200) {
                console.log('Pregunta guardada con éxito:', response.data);
                const updatedQuestions = questions.map((q, i) =>
                    i === index ? { ...q, saved: true } : q
                );
                setQuestions(updatedQuestions);
            }
        } catch (error) {
            console.error('Error al guardar la pregunta:', error);
        }
    };

    const deleteQuestion = (index) => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };

    // Calcular el número de páginas
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(
        indexOfFirstQuestion,
        indexOfLastQuestion
    );

    // Cambiar página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calcular el total de páginas
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(questions.length / questionsPerPage); i++) {
        pageNumbers.push(i);
    }

    const canAddQuestion = questions.length === 0 || questions[questions.length - 1].saved;

    return (
        <DashboardLayout>
            <Meta title="Preguntas y Respuestas" />

            <div className="container mx-auto p-4 text-center">
                <h1 className="text-2xl font-bold mb-12">Preguntas y Respuestas</h1>
                {/* <button
                    onClick={addQuestion}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4"
                    disabled={!canAddQuestion}
                >
                    Agregar Pregunta
                </button>
                {questions.map((question, index) => (
                    <div key={index} className="flex flex-wrap mb-4 gap-2 items-center">
                        <input
                            type="text"
                            placeholder="Pregunta"
                            value={question.pregunta}
                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                            className="input border border-gray-300 rounded px-2 py-1 flex-1"
                        />
                        <input
                            type="text"
                            placeholder="Respuestas"
                            value={question.respuestas}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            className="input border border-gray-300 rounded px-2 py-1 flex-1"
                        />
                        {!question.saved && (
                            <button
                                onClick={() => saveQuestion(index)}
                                className="bg-[#F69100] hover:bg-[#F67100] text-white font-bold py-2 px-4 rounded"
                            >
                                Save
                            </button>
                        )}
                        <button
                            onClick={() => deleteQuestion(index)}
                            className="bg-[#7900AC] hover:bg-[#6000AC] text-white font-bold py-2 px-4 rounded"
                        >
                            Delete
                        </button>
                    </div>
                ))} */}
                <div className="">
                    <p className="text-xl">En estos momentos nos encontramos desarrollando este módulo del servicio</p>
                    <div className="w-full flex justify-center">
                        <img src={DeveloperImg} alt="Desarrollador trabajando" className="w-96 h-96" />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default DataQuestion;
