import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
	check_authenticated,
	load_user,
	login,
	refresh,
	reset_password,
} from "../../redux/actions/auth/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import HomeLayout from "../../hocs/layout/HomeLayout";
import { Helmet } from "react-helmet-async";
import img_logo from "../../assets/img/cf-logo.svg"
import { Meta } from "../../components/navigation/Helmet";
function ResetPassword({
	reset_password,
	isAuthenticated,
	loading,
	refresh,
	check_authenticated,
	load_user,
}) {
	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, []);

	const [formData, setFormData] = useState({
		email: "",
	});

	const { email } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		reset_password(email);
		navigate("/");
	};

	if (isAuthenticated) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<HomeLayout>
			<Meta title="Restablecer contraseña" />
			<div className="mx-auto bg-white flex w-full max-w-md justify-center mt-32 shadow-lg p-4 rounded-lg">
				<div className="w-full max-w-md space-y-8">
					<div>
						<img
							className="mx-auto h-24 w-auto"
							src={img_logo}
							alt="Your Company"
						/>
					</div>
					<form
						onSubmit={(e) => {
							onSubmit(e);
						}}
						className="mt-8 space-y-6"
						action="#"
						method="POST"
					>
						<input type="hidden" name="remember" defaultValue="true" />
						<div className="">
							<div>
								<label htmlFor="email-address" className="sr-only">
									Email address
								</label>
								<input
									id="email-address"
									name="email"
									value={email}
									onChange={(e) => onChange(e)}
									type="email"
									required
									className="input input-bordered w-full text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
									placeholder="Correo electrónico"
								/>
							</div>
						</div>

						<div className="flex items-center justify-between">
							<div className="text-sm">
								<span className="">¿Posees una cuenta?, </span>
								<Link
									to="/login"
									className="font-medium text-green-600 hover:text-green-500"
								>
									Inicia sesión
								</Link>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
							>
								<span className="absolute inset-y-0 left-0 flex items-center pl-3">
									<EnvelopeIcon
										className="h-5 w-5 text-green-500 group-hover:text-green-400"
										aria-hidden="true"
									/>
								</span>
								Enviar correo
							</button>
						</div>
					</form>
				</div>
			</div>
		</HomeLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	reset_password,
	refresh,
	check_authenticated,
	load_user,
})(ResetPassword);
