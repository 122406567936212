import { DATA_SUCCESS, DATA_FAIL } from "./types.js";

import axios from "axios";

export const getData = (params = {}) => async (dispatch) => {
	const config = {
			headers: {
					Accept: "application/json",
					Authorization: `JWT ${localStorage.getItem("access")}`,
			},
			params: params, 
	};

	try {
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/view-data/`, config);
			if (res.status === 200) {
					dispatch({
							type: DATA_SUCCESS,
							payload: res.data,
					});
			}
	}
	catch (error) {
			dispatch({
					type: DATA_FAIL,
			});
	}
};

