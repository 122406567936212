import { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Meta } from "../components/navigation/Helmet";
import LogoCompany from "../assets/img/arvey-logo.png";

function Dashboard() {
  const scan = `${process.env.REACT_APP_API_URL}/media/qr/QRCode.png`
  const [isValidQR, setIsValidQR] = useState(false);

  useEffect(() => {
    const result = validateQR(scan);
    setIsValidQR(result);
  }, [scan]); // Dependencias: si 'scan' cambia, se vuelve a ejecutar este efecto.


  function validateQR(qr) {
    return true;
  }

	console.log(scan);


	return (
		<DashboardLayout>
			<Meta title="Dashboard" />
			<div className="py-5">
				<div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div className="">
						<h1 className="text-2xl font-medium leading-6 text-gray-900">
							Dashboard
						</h1>
						<div className="rounded-lg my-2 p-2 bg-white shadow flex items-center gap-2">
							<img src={LogoCompany} className="w-16" alt="Logo" />
							<div>
								<p className="">Asistente virtual de</p>
								<p><span className="font-bold">Arvey Duque</span></p>
							</div>
						</div>
					</div>
				</div>
				<div className="hero mb-32 lg:mb-0 lg:my-16">
					<div className="hero-content flex-col lg:flex-row">
						<div>
							<h2 className="text-5xl font-bold pb-4">Escanea el código</h2>
							<ul className="list-disc">
								<li className="py-2">Abre WhatsApp en tu teléfono.</li>
								<li className="py-2">Toca Menú en Android o Ajustes en iPhone.</li>
								<li className="py-2">Toca Dispositivos vinculados, y luego, Vincular un dispositivo.</li>
								<li className="py-2">Apunta tu teléfono hacia esta pantalla para escanea el QR.</li>
							</ul>
						</div>
						{isValidQR ? (
							<img src={scan} alt="QR Code" style={{ width: "100%", maxWidth: "500px", borderRadius: "lg", marginBottom: "2px" }} />
							) : ("Sin imagen")}
					</div>
				</div>
					 {/* Condición para mostrar el QR si isValidQR es true */}
					 {isValidQR ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
						</div>
					) : (
						<div>
							<p>El código QR no es válido o no cumple con los requisitos.</p>
						</div>
					)}

			</div>
		
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps, {

})(Dashboard);
