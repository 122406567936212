import { connect } from "react-redux";
import HomeNavbar from "../../components/navigation/HomeNavbar";
import Footer from "../../components/navigation/Footer";

function Layout({ children }) {
  return (
    <main className={""}>
      <HomeNavbar />
      <div className="min-h-screen">
        {children}
      </div>
      <Footer/>
    </main>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Layout);
