import latirLogo from "../../assets/img/cf-logo.svg"
import {IconBrandX, IconBrandInstagram, IconBrandFacebook} from "@tabler/icons-react"

function Footer() {
  return (
    <footer className="footer p-10 bg-[#00496B] text-white">
      <aside>
        <img src={latirLogo} alt="logo latir" className="w-44"></img>
        <p>Latir, latimos por tu bienestar © 2023<br/>Todos los derechos reservados</p>
      </aside>  
      <nav>
        <header className="footer-title">Menú</header> 
        <a href="/#hero" className="link link-hover">Inicio</a>
        <a href="/#about" className="link link-hover">Nosotros</a>
        <a href="/#verify" className="link link-hover">Verificar</a>
        <a href="/#plans" className="link link-hover">Planes</a>
        <a href="/#join-us" className="link link-hover">Únete</a>
      </nav> 
      <nav>
        <header className="footer-title">Legal</header> 
        <a href="#" className="link link-hover">Términos de uso</a>
        <a href="#" className="link link-hover">Política de privacidad</a>
        <a href="#" className="link link-hover">Política de cookies</a>
      </nav>
      <nav>
        <header className="footer-title">Redes Sociales</header> 
        <div className="flex gap-2">
          <a href="#" className="p-2 bg-white text-[#00496B] rounded-full" title="X">
            <IconBrandX size={22} strokeWidth={2} />
          </a>
          <a href="#" className="p-2 bg-white text-[#00496B] rounded-full" title="Instagram">
            <IconBrandInstagram size={22} strokeWidth={2} />
          </a>
          <a href="#" className="p-2 bg-white text-[#00496B] rounded-full" title="Facebook">
            <IconBrandFacebook size={22} strokeWidth={2} />
          </a>
        </div>
      </nav>
    </footer>
  );
}
export default Footer;
